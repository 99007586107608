/*
 * @Date: 2023-03-14 14:02:09
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 14:30:16
 * @FilePath: \J1-M2-QRP-CODE\pages\m2-001.js
 *
 */
import React from "react";
import Register from "../components/register";
import Download from "../components/download" // IOS Android 二维码下载;
import Banner from "../components/hayhar/banner";
import DownHeader from '@/AppHead'; // Header APP 下载 显示
import Header from '@/hayhar/header'; // 头部 组件
import Footer from '@/hayhar/footer'; // 底部内容
import Socialbtn from '@/hayhar/socialbtn'; // 社交按钮
import Modal from '@/modal'; //
import { checkAffQueryString ,Cookie, _handleTheMainSiteUrlOfBackendResponse } from '../actions/util';
import { fetchRequest } from '$CONFIG/Fatch.config';
import { Apiversion } from '../config/Fatch.config';
import HostConfig from '$CONFIG/Host.config'
export const CMSURL = HostConfig.Config.CMSURL;
import { ApiPort } from '$ACTIONS/API';
import Router from 'next/router';


import setupTraces from "../config/trace";
import { bannerConfigs } from "../config/default/banner";
setupTraces('m2-001');

export default class hayHar extends React.Component {

    constructor() {
        super();
        this.state = {
            bannerList: [],
            pagename: '',

            mainSiteUrl : '',
            liveChatUrl : '',
            affiliateUrlLM : '',

            isModal:false,
            error_text : '',

            qrpic:'', // qrcode
            nativeAppUrl:'',

            showDownHeader:false, // 是否显示头部下载按钮   
        }

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.getPageWidth = this.getPageWidth.bind(this);
        this.hideHeader = this.hideHeader.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init() {
        // 获取URL 参数
        checkAffQueryString(global.location.search);
        // 获取当前page
        this.getPageName();
        // 获取banner
        this.getBanner();
        //
        this.QrpLoad();

        this.AffiliateLink();
    }

    /**
     * 获取当前路径
     */
    getPageName() {
        // this.setState({
        //     bannerList : bannerConfigs['m2-001']
        // },()=>{
        //     console.log('====================================');
        //     console.log('bannerList',this.state.bannerList);
        //     console.log('====================================');
        // })

    }

    getBanner() {
        try {
            fetchRequest(ApiPort.Banner1, 'GET')
              .then(res => {
                  if (res && res.length > 0) {
                      this.setState({
                          bannerList: res.map((val, index) => {
                              return {
                                  cmsImageUrlWeb: CMSURL + val.cmsImageUrlWeb,
                                  cmsImageUrlMobile: CMSURL + val.cmsImageUrlMobile,
                                  cmsImageUrlMobileAlt: val.cmsImageUrlMobileAlt,
                                  cmsImageUrlWebAlt: val.cmsImageUrlWebAlt
                              }
                          })
                      });
                  } else {
                      this.setState({bannerList: bannerConfigs['m2-001']});
                  }
              })
        } catch (error) {
            this.setState({bannerList : bannerConfigs['m2-001']});
        }
    }



    async QrpLoad() {

        let data = await fetchRequest(ApiPort.QrpLoad, 'GET', '', Apiversion);
        console.log(data);
        if(data.isSuccess){
            this.setState({
                mainSiteUrl : data.mainSiteUrl,
                liveChatUrl :  data.liveChatUrl,
                nativeAppDownloadUrl : data.nativeAppDownloadUrl,
                affiliateUrlLM: data.affiliateUrlLM

            })
        }
    }


    /**
     *
     * @param {*} nativeAppUrl
     */

    getQrcodeUrl(nativeAppUrl){
        let aff = Cookie.GetCookieKeyValue('CO_affiliate') ?  '?aff=' + Cookie.GetCookieKeyValue('CO_affiliate'): '';
        let params = {
            text : nativeAppUrl
        }
        fetchRequest(ApiPort.Qrcode ,'POST' , params).then(res =>{
            console.log('res===',res);
            this.setState({
                qrpic:res.content
            })
        })
    }


    /**
     * 获取 APP 下载页面
     */
    async AffiliateLink(){
        const affCode = Cookie.GetCookieKeyValue('CO_affiliate') ?  '&affiliateCode=' + Cookie.GetCookieKeyValue('CO_affiliate') : '';
        let Apiversion = '?api-version=1.0&brand=JBO&Platform=Android' + affCode;
        let data = await fetchRequest(ApiPort.AffiliateLink , 'GET' , '' ,Apiversion);
        if(data.isSuccess){
            this.setState({
                nativeAppUrl:data.nativeAppUrl
            },()=>{
                // this.getQrcodeUrl(data.nativeAppUrl);
            })

        }
    }


    // 关闭弹窗
    closeModal(){
        this.setState({
            isModal : false
        })
    }

    /**
     * 显示弹窗
     * @param {string} text
     */
    openModal(text){
        this.setState({
            error_text : text,
            isModal : !this.state.isModal
        })
    }

    /**
     * 获取当前页面宽度
     * @param {number} event
     */
    getPageWidth(event){
        console.log('event',event);
        let showFlag = false;
        if(event < 500){
            showFlag = true;
        }
        this.setState({
            showDownHeader : showFlag
        });
    }


    hideHeader(event){
       this.setState({
        showDownHeader : event
       })
    }

    /**
     * 回到主站
     */
    goHome(){
        global.globalGtag('Style2_QRP','homepage' , 'homepage' ,'event');
        _handleTheMainSiteUrlOfBackendResponse(this.state.mainSiteUrl);
    }

    render(){
        let { mainSiteUrl , qrpic , nativeAppUrl , affiliateUrlLM} = this.state;
        return (

            <div className={`hayhar-page m2-001`}>
                {/* 头部登录按钮  */}
                <Header openModal={this.openModal} mainSiteUrl={mainSiteUrl}></Header>
                {/* banner */}
                <Banner  bannerList={this.state.bannerList} getPageWidth={this.getPageWidth} ></Banner>

                <div className="hayhar-middle">
                    {/* 注册 */}
                    <Register openModal={this.openModal} ></Register>
                    {/* 下载 */}
                    <Download nativeAppUrl={nativeAppUrl} qrpic={qrpic} affiliateUrlLM={affiliateUrlLM}></Download>
                </div>
                {/* 页脚 */}
                <Footer mainSiteUrl={mainSiteUrl} affiliateUrlLM={affiliateUrlLM}></Footer>
                {/* 联系方式 */}
                {/*<Socialbtn liveChatUrl={liveChatUrl}></Socialbtn>*/}
                {/* 弹窗 */}
                <Modal error_text={this.state.error_text} closeModal={this.closeModal} isModal={this.state.isModal}></Modal>
            </div>
        )
    }

}
